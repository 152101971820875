/**
    * @description      : 
    * @author           : zoptal
    * @group            : 
    * @created          : 21/06/2021 - 15:42:46
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 21/06/2021
    * - Author          : zoptal
    * - Modification    : 
**/

export const environment = {
  production: false,
  SERVER_URL: "https://apis.updogdates.com/v1/",
  IMG_BASE_URL: "https://apis.updogdates.com",
  
  // SERVER_URL: "https://zoptaldev.com:5000/v1/",
  // IMG_BASE_URL: "https://zoptaldev.com:5000",
  STATUS_CODE: {

    SUCCESS: {
      CODE: 200,
      MESSAGE: 'SUCCESS'
    },
    FAILURE: {
      CODE: 201,
      MESSAGE: 'ERROR'
    },
    SESSION_EXPIRE: {
      CODE: 203,
      MESSAGE: 'SUCCESS'
    },
    DATA_NOT_FOUND: {
      CODE: 202,
      MESSAGE: 'SUCCESS'
    },
    INTERNEL_SERVER_ERROR: {
      CODE: 500,
      MESSAGE: 'ERROR'
    },
    API_NOT_FOUND: {
      CODE: 404,
      MESSAGE: 'ERROR'
    },
  }
};

