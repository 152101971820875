import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ApiCallingService } from "../../configs/api-calling.service";
import { NotifierService } from "angular-notifier";

@Component({
  selector: 'app-view-category',
  templateUrl: './view-category.component.html',
  styleUrls: ['./view-category.component.css']
})
export class ViewCategoryComponent implements OnInit {
  public category_name = '';
  public submitted = false;
  public responseData: any = [];
  fileToUpload: File = null;
  public fileUrl: any = '';
  public category_id: string = '';
  public IMAGE_BASE_URL: string = '';
  private readonly notifier: NotifierService;

  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private router: Router,
    public api: ApiCallingService,
    private activatedRoute: ActivatedRoute,
    notifierService: NotifierService

  ) {
    this.notifier = notifierService;
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.category_id = params['id'];
      // this.getData()
    })
    this.getData()
  }

  public IMG_BASE_URL = this.api.IMAGE_BASE_URL;

  getData() {

    // stop here if form is invalid 

    let api_url = 'admin/category_detail?category_id=' + this.category_id

    this.api.getRequest(api_url, {}).then(
      (res) => {
        if (res != undefined && (res.code == 200 || res.code == 202)) {
          this.responseData = res.result;
          this.category_name = this.responseData.category_name;
          this.fileUrl = this.responseData.image? this.IMG_BASE_URL + '/' + 'category' + '/' + this.responseData.image:''
        } else {
          if (res) {
            this.notifier.notify('error', `Error: ${res.message}`);
          }
        }
      },
      (err) => {

      }
    );

  }

  resetForm() {
    this.submitted = false;
    this.fileToUpload == null;
    this.fileUrl = '';
    this.category_name = '';
  }

  editCategory() {
    this.submitted = true;
    if (this.category_name != '') {
      let api_url = 'admin/add_category'

      var formData = new FormData();
      formData.append('category_name', this.category_name)
      formData.append('file', this.fileToUpload == null ? '' : this.fileToUpload)
      // formData.append('type', 1)
      formData.append('_id', this.category_id)

      this.api.postRequest(api_url, formData).then(
        (res) => {
          this.submitted = false;
          if (res.code == 200) {
            this.notifier.notify('success', `Success: ${res.message}`);
            this.router.navigate(['/admin/category_management']);
          } else {
            this.notifier.notify('error', `Error: ${res.message}`);
          }
        },
        (err) => {

        }
      );
    }
  }

  handleFileInput(files: FileList) {
    var mimeType = files.item(0).type;
    // if (mimeType.match(/image\/*/) == null) {
    // this.file_warning= "Only images are supported.";
    // return;
    // }
    var reader = new FileReader();
    // var img = new Image();
    // this.imagePath = event.target.files;
    reader.readAsDataURL(files.item(0));
    reader.onload = (_event) => {
      this.fileUrl = reader.result;
    }

    this.fileToUpload = files.item(0);
  }
}
