/**
    * @description      : 
    * @author           : zoptal
    * @group            : 
    * @created          : 21/06/2021 - 09:55:40
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 21/06/2021
    * - Author          : zoptal
    * - Modification    : 
**/
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ApiCallingService } from "../../../configs/api-calling.service";
import Swal from 'sweetalert2';
import { NotifierService } from "angular-notifier";

@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.css']
})
export class AddProductComponent implements OnInit {


  public title = 'Add Product';
  isSubmitted = false;
  countryForm: FormGroup;
  myFiles: string[] = [];
  files: any=[];
  public responseData: any = [];
  public responseHoles: any = [];
  public latitude: any =  30.7046;
  public longitude: any = 76.7179;
  public eventLocation: any = '';
    public options: any = '';

  private readonly notifier: NotifierService;
  itemsPerPage: number = 10;
  totalRecords: number;
  skip: number = 0;
  p: number = 1;
  selectedCourse = '';
  selectedCategoryName = '';


  keyword = 'name';
  

  constructor(
      private http: HttpClient,
      private formBuilder: FormBuilder,
      private router: Router,
      public api: ApiCallingService,
      notifierService: NotifierService

  ) {
    this.notifier = notifierService;
  }

  ngOnInit() {

    this.countryForm  = this.formBuilder.group({
          productName : ['', Validators.required],
          productDescription : ['', Validators.required],
          productPrice : ['', Validators.required],
          link : ['',Validators.required],
          productImage: ['', Validators.required],
          productTag: [null],
    });
   
  }

 
  get productName() {return this.countryForm.get('productName'); }
  get productDescription() {return this.countryForm.get('productDescription'); }
  get productPrice() {return this.countryForm.get('productPrice'); }
  get link() {return this.countryForm.get('link'); }
  get productImage() {return this.countryForm.get('productImage'); }
  get productTag() {return this.countryForm.get('productTag'); }

  handleFileInput(e) {
  
    //this.fileToUpload =files.item(0);
    //console.log(this.fileToUpload);
    console.log(e.target.files.length);
    for (var i = 0; i < e.target.files.length; i++) {  
      this.myFiles.push(e.target.files[i]);  
    } 
    console.log( this.myFiles);
  }

  onSubmit() {   
    this.isSubmitted = true;
    if (!this.countryForm.valid) {
      return false;
    } else {

      const formData: FormData = new FormData();
      formData.append('productName', this.countryForm.value.productName)
      formData.append('productDescription', this.countryForm.value.productDescription)
      formData.append('productPrice', this.countryForm.value.productPrice)
      formData.append('productTag', this.countryForm.value.productTag)
      formData.append('link',this.countryForm.value.link)
      for (var i = 0; i < this.myFiles.length; i++) {  
        formData.append("productImage", this.myFiles[i]);  
      }

  
     let api_url = 'products/add_new'
     this.api.postRequest(api_url, formData).then(
          (res) => {
            if (res.code == 200) {
                 Swal.fire(
                  'Success!',
                   res.message,
                  'success'
                )
              this.router.navigate(['/admin/product_management'])
            } else {
              Swal.fire(
                  'Error!',
                   res.message,
                  'error'
                )
            }
            
          },
          (err) => {

          }
        );


      console.log(JSON.stringify(this.countryForm.value))
    }

  }

  public handleAddressChange(address) {

    //console.log("+++++++++++++++++", address.formatted_address)

      this.eventLocation = address.formatted_address;

     // https://maps.googleapis.com/maps/api/geocode/json?address=1600+Amphitheatre+Parkway,+Mountain+View,+CA&key=AIzaSyDgc7uiAlf_3AJPFOXfYpJgfvTzpCk6aN4

    //  var GEOCODING = 'https://maps.googleapis.com/maps/api/geocode/json?address=' + address.formatted_address + '&language=en&key=AIzaSyDe06nYV9PKC3LHUOhqNADqtO1lIlcoaC0';
     
    //  $.getJSON(GEOCODING).then(
    //    (res) => {

    //         this.latitude= res.results[0].geometry.location.lat
    //         this.longitude= res.results[0].geometry.location.lng
    //  },
    // (err) => {
    //   }
    //  );


        // Do some stuff
    }
  

}
