/**
    * @description      : 
    * @author           : zoptal
    * @group            : 
    * @created          : 21/06/2021 - 09:56:37
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 21/06/2021
    * - Author          : zoptal
    * - Modification    : 
**/
import { Component, OnInit, ViewChild, EventEmitter, Output, AfterViewInit, Input } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ApiCallingService } from "../../../configs/api-calling.service";
import Swal from 'sweetalert2';
import { NotifierService } from "angular-notifier";
declare var $: any;
import * as moment from 'moment';
import { GooglePlaceDirective } from "node_modules/ngx-google-places-autocomplete/ngx-google-places-autocomplete.directive";


declare var google: any;

@Component({
  selector: 'app-edit-product',
  templateUrl: './edit-product.component.html',
  styleUrls: ['./edit-product.component.css']
})
export class EditProductComponent implements OnInit {

  @ViewChild("placesRef") placesRef: GooglePlaceDirective;

  public productName = '';
  public productDescription = '';
  public productPrice = '';
  public productTag = '';
  public link = '';
  // public productTime = '';

  public options: any = '';


  public submitted = false;
  fileToUpload: File = null;
  public fileUrl: any = '';

  public title = 'Edit Product';
  public responseCat: any = [];

  public responseData: any = [];

  public search = '';
  // pagination var
  itemsPerPage: number = 10;
  totalRecords: number;
  skip: number = 0;
  p: number = 1;
  private readonly notifier: NotifierService;
  public IMAGE_BASE_URL;
  public id: string = '';
  myFiles: string[] = [];
  file: String = "";
  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private router: Router,
    public api: ApiCallingService,
    notifierService: NotifierService,
    private activatedRoute: ActivatedRoute


  ) {
    this.notifier = notifierService;
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.id = params['id'];
      this.getData()
    })
    this.IMAGE_BASE_URL = this.api.IMAGE_BASE_URL;

  }



  getData() {

    // stop here if form is invalid 

    let api_url = 'product/detail?product_id=' + this.id

    this.api.getRequest(api_url, {}).then(
      (res) => {
        if (res != undefined && res.code == 200) {
          this.responseData = res.result;
          this.link = this.responseData.link;
          this.productName = this.responseData.productName;
          this.productDescription = this.responseData.productDescription;
          // this.productDate = moment.unix(this.responseData.productDate).format( "YYYY-MM-DD" );
          // this.productTime =moment.unix(this.responseData.productTime).utc().format("HH:mm")
          //moment().tz("America/Los_Angeles").format();
          this.productPrice = this.responseData.productPrice;
          this.productTag = this.responseData.productTag === false ? '' : this.responseData.productTag


          this.fileUrl = this.responseData.productImage.length > 0 ? this.IMAGE_BASE_URL + '/images/' + this.responseData.productImage[0] : '';

        } else {
          if (res) {
            alert(res.message)
          }
        }
      },
      (err) => {

      }
    );

  }

  editproduct() {

    this.submitted = true;


    if (this.productName != '') {
      let api_url = 'products/add_new'

      var formData = new FormData();


      console.log("+++++++++=", this.productTag)
      formData.append('link', this.link)
      formData.append('productName', this.productName)
      for (var i = 0; i < this.myFiles.length; i++) {
        formData.append("productImage", this.myFiles[i]);
      }
      formData.append('productDescription', this.productDescription)

      formData.append('productPrice', this.productPrice)
      formData.append('productTag', this.productTag)

      formData.append('_id', this.id)
      //if (this.fileToUpload) {

      this.api.postRequest(api_url, formData).then(
        (res) => {
          this.submitted = false;
          if (res.code == 200) {
            Swal.fire(
              'Success!',
              res.message,
              'success'
            )
            this.router.navigate(['/admin/product_management']);
          } else {
            Swal.fire(
              'Error!',
              res.message,
              'error'
            )
          }
        },
        (err) => {

        }
      );
      // } else {
      //   console.log("File is required++++++++")
      // }
    }
  }



  handleFileInput(e) {

    //this.fileToUpload =files.item(0);
    //console.log(this.fileToUpload);
    console.log(e.target.files.length);
    for (var i = 0; i < e.target.files.length; i++) {
      this.myFiles.push(e.target.files[i]);
    }
    console.log(this.myFiles);

    this.fileToUpload = e.target.files[0];
  }



  public handleAddressChange(address) {

    //   console.log("+++++++++++++++++", address.formatted_address)

    //     this.eventLocation = address.formatted_address;

    //    // https://maps.googleapis.com/maps/api/geocode/json?address=1600+Amphitheatre+Parkway,+Mountain+View,+CA&key=AIzaSyDgc7uiAlf_3AJPFOXfYpJgfvTzpCk6aN4

    //     var GEOCODING = 'https://maps.googleapis.com/maps/api/geocode/json?address=' + address.formatted_address + '&language=en&key=AIzaSyDe06nYV9PKC3LHUOhqNADqtO1lIlcoaC0';

    //    $.getJSON(GEOCODING).then(
    //      (res) => {

    //         this.latitude= res.results[0].geometry.location.lat
    //         this.longitude= res.results[0].geometry.location.lng
    // },
    //     (err) => {
    //     }
    //    );


    // Do some stuff
  }

}
