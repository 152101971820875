/**
    * @description      :
    * @author           : zoptal
    * @group            :
    * @created          : 23/06/2021 - 18:25:16
    *
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 23/06/2021
    * - Author          : zoptal
    * - Modification    :
**/
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  SERVER_URL: "https://apis.updogdates.com/v1/",
  IMG_BASE_URL: "https://apis.updogdates.com",
  // SERVER_URL: "https://zoptaldev.com:5000/v1/",
  // IMG_BASE_URL: "https://zoptaldev.com:5000",
  // SERVER_URL: "http://127.0.0.1:5000/v1/",
  // IMG_BASE_URL: "https://127.0.0.1:5000",
  STATUS_CODE: {

    SUCCESS: {
      CODE: 200,
      MESSAGE: 'SUCCESS'
    },
    FAILURE: {
      CODE: 201,
      MESSAGE: 'ERROR'
    },
    SESSION_EXPIRE: {
      CODE: 203,
      MESSAGE: 'SUCCESS'
    },
    DATA_NOT_FOUND: {
      CODE: 202,
      MESSAGE: 'SUCCESS'
    },
    INTERNEL_SERVER_ERROR: {
      CODE: 500,
      MESSAGE: 'ERROR'
    },
    API_NOT_FOUND: {
      CODE: 404,
      MESSAGE: 'ERROR'
    },
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
