/**
    * @description      : 
    * @author           : zoptal
    * @group            : 
    * @created          : 21/06/2021 - 09:55:40
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 21/06/2021
    * - Author          : zoptal
    * - Modification    : 
**/
import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ApiCallingService } from "../../configs/api-calling.service";
import { NotifierService } from "angular-notifier";
@Component({
  selector: 'app-add-category',
  templateUrl: './add-category.component.html',
  styleUrls: ['./add-category.component.css']
})
export class AddCategoryComponent implements OnInit {
  public category_name = '';
  public submitted = false;
  public responseData: any = [];
  fileToUpload: File = null;
  public fileUrl: any = '';
  private readonly notifier: NotifierService;

  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private router: Router,
    public api: ApiCallingService,
    notifierService: NotifierService

  ) {
    this.notifier = notifierService;
  }

  ngOnInit() {
  }

  addCategory() {
    this.submitted = true;
    if (this.category_name != '') {
      let api_url = 'admin/add_category'

      var formData = new FormData();
      formData.append('category_name', this.category_name)
      formData.append('file', !this.fileToUpload ? '' : this.fileToUpload)

      // if (this.fileToUpload) {

        this.api.postRequest(api_url, formData).then(
          (res) => {
            this.submitted = false;
            this.fileToUpload = null;
            this.category_name = '';
            this.fileUrl = ''
            if (res.code == 200) {
              this.notifier.notify('success', `Success: Breed added successfully.`);
              this.router.navigate(['/admin/category_management'])
            } else {
              this.notifier.notify('error', `Error: ${res.message}`);
            }
          },
          (err) => {

          }
        );
      // }
    }
  }

  handleFileInput(files: FileList) {
    var mimeType = files.item(0).type;
   
    var reader = new FileReader();
  
    reader.readAsDataURL(files.item(0));
    reader.onload = (_event) => {
      this.fileUrl = reader.result;
    }

    this.fileToUpload = files.item(0);
  }
  resetForm() {
    this.submitted = false;
    this.fileToUpload == null;
    this.fileUrl = '';
    this.category_name = '';
  }
}
