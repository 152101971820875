/**
    * @description      :
    * @author           : zoptal
    * @group            :
    * @created          : 28/06/2021 - 16:37:17
    *
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 28/06/2021
    * - Author          : zoptal
    * - Modification    :
**/
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './view/login/login.component';
import { ForgotPasswordComponent } from './view/forgot-password/forgot-password.component';
import { DashboardComponent } from './view/dashboard/dashboard.component';
import { SubscriptionComponent } from './view/subscription/subscription.component';

//services
import { CanActivateRouteGuard } from "./configs/can-activate-route.guard"
import { Interceptor } from './configs/interceptor';
// modules
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxSpinnerModule } from "ngx-spinner";
import { NgxPaginationModule } from 'ngx-pagination';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NotifierModule } from "angular-notifier";
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { Angular2CsvModule } from 'angular2-csv';



// search module
import { Ng2SearchPipeModule } from 'ng2-search-filter';

//services
import { HeaderComponent } from './view/header/header.component';
import { MainComponent } from './view/main/main.component';
import { FooterComponent } from './view/footer/footer.component';
import { SidebarComponent } from './view/sidebar/sidebar.component';
import { UserManagementComponent } from './view/user-management/user-management.component';
import { UserDetailComponent } from './view/user-detail/user-detail.component';
import { ChangePasswordComponent } from './view/change-password/change-password.component';
import { ContentManagementComponent } from './view/content-management/content-management.component';
import { PagenotfoundComponent } from './view/pagenotfound/pagenotfound.component';
import { CaptalisePipe } from './configs/captalise.pipe';
import { CategoryComponent } from './view/category/category.component';
import { AddCategoryComponent } from './view/add-category/add-category.component';
import { EditCategoryComponent } from './view/edit-category/edit-category.component';
import { ViewCategoryComponent } from './view/view-category/view-category.component';
import { SubcategoryComponent } from './view/subcategory/subcategory.component';
import { PostDetailComponent } from './view/post-detail/post-detail.component';
import { UpdateProfileComponent } from './view/update-profile/update-profile.component';
import { PackageManagementComponent } from './view/package-management/package-management.component';
import { ReportUserComponent } from './view/report-user/report-user.component';
import { FeedbackManagementComponent } from './view/feedback-management/feedback-management.component';
import { EventManagementComponent } from './view/event-management/event-management.component';
import { AddEventComponent } from './view/event-management/add-event/add-event.component';
import { EditEventComponent } from './view/event-management/edit-event/edit-event.component';
import { EventCategoryManagementComponent } from './view/event-category-management/event-category-management.component';
import { AddEventCategoryComponent } from './view/event-category-management/add-event-category/add-event-category.component';
import { EditEventCategoryComponent } from './view/event-category-management/edit-event-category/edit-event-category.component';
import { ViewEventCategoryComponent } from './view/event-category-management/view-event-category/view-event-category.component';
import { ProductManagementComponent } from './view/product-management/product-management.component';
import { AddProductComponent } from './view/product-management/add-product/add-product.component';
import { EditProductComponent } from './view/product-management/edit-product/edit-product.component';
import { PromotionalMessagesComponent } from './view/promotional-messages/promotional-messages.component';
// import { CaptalisePipe } from 'src/configs/captalise.pipe';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotPasswordComponent,
    DashboardComponent,
    ReportUserComponent,
    HeaderComponent,
    MainComponent,
    FooterComponent,
    SidebarComponent,
    UserManagementComponent,
    UserDetailComponent,
    ChangePasswordComponent,
    ContentManagementComponent,
    PagenotfoundComponent,
    CaptalisePipe,
    CategoryComponent,
    AddCategoryComponent,
    EditCategoryComponent,
    ViewCategoryComponent,
    SubcategoryComponent,
    PostDetailComponent,
    UpdateProfileComponent,
    SubscriptionComponent,
    PackageManagementComponent,
    FeedbackManagementComponent,
    EventManagementComponent,
    AddEventComponent,
    EditEventComponent,
    EventCategoryManagementComponent,
    AddEventCategoryComponent,
    EditEventCategoryComponent,
    ViewEventCategoryComponent,
    ProductManagementComponent,
    AddProductComponent,
    EditProductComponent,
    PromotionalMessagesComponent,
    // ApiCallingService
  ],
  exports: [
  ],
  imports: [
    GooglePlaceModule,
    Angular2CsvModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxSpinnerModule,
    NgxPaginationModule,
    CKEditorModule,
    NotifierModule,
    Ng2SearchPipeModule
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
      multi: true
    },
    CanActivateRouteGuard
  ]
})
export class AppModule { }
