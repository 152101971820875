/**
    * @description      : 
    * @author           : zoptal
    * @group            : 
    * @created          : 21/06/2021 - 09:53:21
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 21/06/2021
    * - Author          : zoptal
    * - Modification    : 
**/
import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ApiCallingService } from "../../configs/api-calling.service";
import Swal from 'sweetalert2';
declare var $: any;
@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit {
  public title = 'Breed Management';
  public responseData: any = [];
  public search = '';
  // pagination var
  itemsPerPage: number = 10;
  totalRecords: number;
  skip: number = 0;
  p: number = 1;

  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private router: Router,
    public api: ApiCallingService,
  ) {
  }
  ngOnInit() {
    this.getData()
  }

  getData() {

    // stop here if form is invalid 
    if (this.search) {
      this.p = 1;
      this.skip = this.itemsPerPage * this.p - this.itemsPerPage;
    }
    let api_url = 'admin/category_management?skip=' + this.skip + '&limit=' + this.itemsPerPage + '&search=' + this.search;

    this.api.getRequest(api_url, {}).then(
      (res) => {
        if (res != undefined && (res.code == 200 || res.code == 202)) {
          this.totalRecords = res.totalCount;
          this.responseData = res.data
        } else {
          if (res) {
            alert(res.message)
          }
        }
      },
      (err) => {

      }
    );

  }

  activeInactiveCategory(val, status) {
    Swal.fire({
      text: !status ? 'Are you sure, You want to inactive this category ?' : 'Are you sure, You want to active this category?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Submit',
      cancelButtonText: 'Cancel'
    }).then((result) => {

      if (result.value) {

        this.api.postRequest('admin/activeUnactiveCategory', {
          category_id: val,
          active: status ? true : false
        }).then(
          (res) => {

            if (res && res.code == 200) {
              Swal.fire(
                'Success!',
                status ? 'Category marked active successfully.' : 'Category marked inactive successfully.',
                'success'
              )
              for (var i in this.responseData) {
                if (this.responseData[i]._id.toString() == val.toString()) {
                  this.responseData[i].active = status;
                }
              }

            } else {
              Swal.fire(
                'Something went wrong.',
                'Please try again after some time.',
                'error'
              )
            }
          },
          (err) => {
            Swal.fire(
              'Something went wrong.',
              'Please try again after some time.',
              'error'
            )
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your request has been cancelled',
          'error'
        )
      }
    })
  }


  pageChanged(val) {

    this.skip = this.itemsPerPage * val - this.itemsPerPage;
    this.getData()

  }

}
