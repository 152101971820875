/**
    * @description      : 
    * @author           : zoptal
    * @group            : 
    * @created          : 21/06/2021 - 09:56:37
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 21/06/2021
    * - Author          : zoptal
    * - Modification    : 
**/
import { Component, OnInit, ViewChild, EventEmitter, Output, AfterViewInit, Input } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ApiCallingService } from "../../../configs/api-calling.service";
import Swal from 'sweetalert2';
import { NotifierService } from "angular-notifier";
declare var $: any;
import * as moment from 'moment';
import { GooglePlaceDirective } from "node_modules/ngx-google-places-autocomplete/ngx-google-places-autocomplete.directive";


declare var google: any;


@Component({
  selector: 'app-edit-event',
  templateUrl: './edit-event.component.html',
  styleUrls: ['./edit-event.component.css']
})
export class EditEventComponent implements OnInit {
  @ViewChild("placesRef") placesRef : GooglePlaceDirective;
  public event_name = '';
  public event_description = '';
  public event_category = '';
  public latitude = '';
  public longitude = '';
  public event_date = '';
  public event_time = '';
  public eventLocation = '';
  public event_location = '';
  public eventLink = '';
  public buyTicket = '';
  public options: any = '';


  public submitted = false;
  fileToUpload: File = null;
  public fileUrl: any = '';

  public title = 'Edit Event';
  public responseCat: any = [];

  public responseData: any = [];

  public search = '';
  // pagination var
  itemsPerPage: number = 10;
  totalRecords: number;
  skip: number = 0;
  p: number = 1;
  private readonly notifier: NotifierService;
  public IMAGE_BASE_URL;
  public id: string = '';
  myFiles: string[] = [];
  file:String="";
  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private router: Router,
    public api: ApiCallingService,
    notifierService: NotifierService,
    private activatedRoute: ActivatedRoute


  ) {
    this.notifier = notifierService;
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.id = params['id'];
      this.getData()
    })
    this.IMAGE_BASE_URL = this.api.IMAGE_BASE_URL;
          this.getCategory()

  }


  getCategory(){

    // stop here if form is invalid 
  
    let api_url = 'admin/category_event_list?skip=' + this.skip + '&limit=' + this.itemsPerPage;

    this.api.getRequest(api_url, {}).then(
      (res) => {
        if (res != undefined && (res.code == 200 || res.code == 202)) {
          this.totalRecords = res.totalCount;
          this.responseCat = res.data
        } else {
          if (res) {
            alert(res.message)
          }
        }
      },
      (err) => {

      }
    );




  }


  getData() {

    // stop here if form is invalid 

    let api_url = 'admin/event_detail?event_id=' + this.id

    this.api.getRequest(api_url,{}).then(
      (res) => {
        if (res != undefined && res.code == 200) {
                    this.responseData = res.result;  
          this.event_name = this.responseData.eventName;
          this.event_description = this.responseData.eventDescription;
          this.event_date = moment.unix(this.responseData.eventTime).format( "YYYY-MM-DD" );
          this.event_time =moment.unix(this.responseData.eventTime).utc().format("HH:mm")
          //moment().tz("America/Los_Angeles").format();
          this.event_location = this.responseData.eventLocation;
          this.event_category = this.responseData.eventCategory;
          this.eventLink = this.responseData.eventLink;
          this.buyTicket = this.responseData.buyTicket?'true':'false';
          this.latitude = this.responseData.latitude;
          this.longitude = this.responseData.longitude;
          this.fileUrl =this.responseData.eventImage.length>0 ? this.IMAGE_BASE_URL+'/images/'+this.responseData.eventImage[0] : '';
          
        } else {
          if (res) {
            alert(res.message)
          }
        }
      },
      (err) => {

      }
    );

  }

  editevent() {


    this.submitted = true; 


    if (this.event_name != '') {
      let api_url = 'admin/add_event'

      var formData = new FormData();
      formData.append('eventName', this.event_name)
      for (var i = 0; i < this.myFiles.length; i++) {  
        formData.append("eventImage", this.myFiles[i]);  
      }

      formData.append('eventTime', this.event_time)
      formData.append('eventDate', this.event_date)
      formData.append('latitude', this.latitude)
      formData.append('longitude', this.longitude)
      formData.append('buyTicket',this.buyTicket)
      formData.append('eventLocation', this.eventLocation)
      formData.append('eventDescription', this.event_description)
      formData.append('eventCategory', this.event_category)
      formData.append('eventLink', this.eventLink)
      formData.append('_id',this.id)
      //if (this.fileToUpload) {

        this.api.postRequest(api_url, formData).then(
          (res) => {
            this.submitted = false;
            if (res.code == 200) {
                 Swal.fire(
                  'Success!',
                   res.message,
                  'success'
                )
              this.router.navigate(['/admin/event_management']);
            } else {
              Swal.fire(
                  'Error!',
                   res.message,
                  'error'
                )
            }
          },
          (err) => {

          }
        );
      // } else {
      //   console.log("File is required++++++++")
      // }
    }
  }

  

  handleFileInput(e) {
  
    //this.fileToUpload =files.item(0);
    //console.log(this.fileToUpload);
    console.log(e.target.files.length);
    for (var i = 0; i < e.target.files.length; i++) {  
      this.myFiles.push(e.target.files[i]);  
    } 
    console.log( this.myFiles);

    this.fileToUpload = e.target.files[0];
  }



   public handleAddressChange(address) {

    console.log("+++++++++++++++++", address.formatted_address)

      this.eventLocation = address.formatted_address;

     // https://maps.googleapis.com/maps/api/geocode/json?address=1600+Amphitheatre+Parkway,+Mountain+View,+CA&key=AIzaSyDgc7uiAlf_3AJPFOXfYpJgfvTzpCk6aN4

      var GEOCODING = 'https://maps.googleapis.com/maps/api/geocode/json?address=' + address.formatted_address + '&language=en&key=AIzaSyDe06nYV9PKC3LHUOhqNADqtO1lIlcoaC0';
     
     $.getJSON(GEOCODING).then(
       (res) => {

          this.latitude= res.results[0].geometry.location.lat
          this.longitude= res.results[0].geometry.location.lng
  },
      (err) => {
      }
     );


        // Do some stuff
    }

}
