/**
    * @description      : 
    * @author           : zoptal
    * @group            : 
    * @created          : 28/06/2021 - 15:38:20
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 28/06/2021
    * - Author          : zoptal
    * - Modification    : 
**/
import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ApiCallingService } from "../../configs/api-calling.service";
import { NgxSpinnerService } from "ngx-spinner";
import Swal from 'sweetalert2';
declare var $: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  public title = 'Dashboard';
  public responseData: any = {};

  constructor(
    private http: HttpClient,
    private router: Router,
    public api: ApiCallingService,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit() {
    this.getData();
  }

  getData() {

    let api_url = 'admin/dashboard';
    this.spinner.show();
    this.api.getRequest(api_url, {}).then(
      (res) => {
        this.spinner.hide();
        if (res != undefined && (res.code == 200 || res.code == 202)) {
          this.responseData = res.result;
        } else {
          if (res) {
            alert(res.message)
          }
        }
      },
      (err) => {

      }
    );

  }
}
