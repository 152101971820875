import { Component, OnInit } from '@angular/core';
import { NotifierService } from "angular-notifier";
import { ApiCallingService } from "../../configs/api-calling.service";
import { Form } from '@angular/forms';

@Component({
  selector: 'app-promotional-messages',
  templateUrl: './promotional-messages.component.html',
  styleUrls: ['./promotional-messages.component.css']
})
export class PromotionalMessagesComponent implements OnInit {

  constructor(    notifierService: NotifierService,
    public api: ApiCallingService,
    ) {     this.notifier = notifierService;
    }
  private readonly notifier: NotifierService;

  title : String = "Promotional Messages"

  ngOnInit() {
  }

  sendNotifications(data:any){
    if(data.title.trim() == '' || data.title == null || data.title == undefined){
      this.notifier.notify('error','Title can not be blank')
    }else if(data.message.trim() == '' || data.message == null || data.message == undefined){
      this.notifier.notify('error','Message can not be blank')
    }else{
      this.api.postRequest('admin/promotions', data).then(
        (res) => {

          if (res != undefined && (res.code == 200 || res.code == 202)) {
            this.notifier.notify('success',res.message)
          } else {

            if (res) {
              this.notifier.notify('success',res.message)
            }
          }
        },
        (err) => {
          this.notifier.notify('error',err.message)
        }
      );
    }
  }

}
