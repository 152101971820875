import { PromotionalMessagesComponent } from './view/promotional-messages/promotional-messages.component';
/**
    * @description      :
    * @author           : zoptal
    * @group            :
    * @created          : 28/06/2021 - 16:37:14
    *
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 28/06/2021
    * - Author          : zoptal
    * - Modification    :
**/
import { NgModule } from '@angular/core';
import { Routes, RouterModule, CanActivate } from '@angular/router';
import {
  CanActivateRouteGuard as AuthGuard
} from './configs/can-activate-route.guard';
// modules
import { LoginComponent } from './view/login/login.component';
import { ForgotPasswordComponent } from './view/forgot-password/forgot-password.component';
import { DashboardComponent } from './view/dashboard/dashboard.component';
import { MainComponent } from './view/main/main.component';
import { UserManagementComponent } from './view/user-management/user-management.component';
import { SubcategoryComponent } from './view/subcategory/subcategory.component';
import { UserDetailComponent } from './view/user-detail/user-detail.component';
import { ChangePasswordComponent } from './view/change-password/change-password.component';
import { ContentManagementComponent } from './view/content-management/content-management.component';
import { PagenotfoundComponent } from './view/pagenotfound/pagenotfound.component';
import { CategoryComponent } from './view/category/category.component';
import { AddCategoryComponent } from './view/add-category/add-category.component';
import { EditCategoryComponent } from './view/edit-category/edit-category.component';
import { ViewCategoryComponent } from './view/view-category/view-category.component';
import { UpdateProfileComponent } from './view/update-profile/update-profile.component';
import { SubscriptionComponent } from './view/subscription/subscription.component';
import { ReportUserComponent } from './view/report-user/report-user.component';
import { PackageManagementComponent } from './view/package-management/package-management.component';
import { FeedbackManagementComponent } from './view/feedback-management/feedback-management.component';
import { EventManagementComponent } from './view/event-management/event-management.component';
import { AddEventComponent } from './view/event-management/add-event/add-event.component';
import { EditEventComponent } from './view/event-management/edit-event/edit-event.component';
import { EventCategoryManagementComponent } from './view/event-category-management/event-category-management.component';
import { AddEventCategoryComponent } from './view/event-category-management/add-event-category/add-event-category.component';
import { EditEventCategoryComponent } from './view/event-category-management/edit-event-category/edit-event-category.component';
import { ViewEventCategoryComponent } from './view/event-category-management/view-event-category/view-event-category.component';
import { ProductManagementComponent } from './view/product-management/product-management.component';

import { AddProductComponent } from './view/product-management/add-product/add-product.component';
import { EditProductComponent } from './view/product-management/edit-product/edit-product.component';


const routes: Routes = [
  {
    path: 'login', component: LoginComponent

  },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  {
    path: 'admin', component: MainComponent,
    children: [
      { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
      { path: 'user_management', component: UserManagementComponent, canActivate: [AuthGuard] },
      { path: 'subscription_management', component: SubscriptionComponent, canActivate: [AuthGuard] },
      { path: 'packages_management', component: PackageManagementComponent, canActivate: [AuthGuard] },
      { path: 'feedback_management', component: FeedbackManagementComponent, canActivate: [AuthGuard] },
      { path: 'user_management/user_detail/:id', component: UserDetailComponent, canActivate: [AuthGuard] },

      { path: 'event_management', component: EventManagementComponent, canActivate: [AuthGuard] },
      { path: 'event_management/add_event', component: AddEventComponent, canActivate: [AuthGuard] },
      { path: 'event_management/edit_event/:id', component: EditEventComponent, canActivate: [AuthGuard] },

      { path: 'product_management', component: ProductManagementComponent, canActivate: [AuthGuard] },
      { path: 'product_management/add_product', component: AddProductComponent, canActivate: [AuthGuard] },
      { path: 'product_management/edit_product/:id', component: EditProductComponent, canActivate: [AuthGuard] },

      { path: 'promotional_messages', component: PromotionalMessagesComponent, canActivate: [AuthGuard] },
      { path: 'category_management', component: CategoryComponent, canActivate: [AuthGuard] },
      { path: 'category_management/add_category', component: AddCategoryComponent, canActivate: [AuthGuard] },
      { path: 'category_management/edit_category/:id', component: EditCategoryComponent, canActivate: [AuthGuard] },
      { path: 'category_management/category_detail/:id', component: ViewCategoryComponent, canActivate: [AuthGuard] },


      { path: 'category_event_management', component: EventCategoryManagementComponent, canActivate: [AuthGuard] },
      { path: 'category_event_management/add_event_category', component: AddEventCategoryComponent, canActivate: [AuthGuard] },
      { path: 'category_event_management/edit_event_category/:id', component: EditEventCategoryComponent, canActivate: [AuthGuard] },
      { path: 'category_event_management/category_event_detail/:id', component: ViewEventCategoryComponent, canActivate: [AuthGuard] },

      { path: 'reported_user', component: ReportUserComponent, canActivate: [AuthGuard] },
      { path: 'update_profile', component: UpdateProfileComponent, canActivate: [AuthGuard] },
      { path: 'terms_and_condition', component: ContentManagementComponent, canActivate: [AuthGuard] },
      { path: 'artist_terms_and_condition', component: ContentManagementComponent, canActivate: [AuthGuard] },
      { path: 'privacy_policy', component: ContentManagementComponent, canActivate: [AuthGuard] },
      { path: 'artist_privacy_policy', component: ContentManagementComponent, canActivate: [AuthGuard] },
      { path: 'disclaimer', component: ContentManagementComponent, canActivate: [AuthGuard] },
      { path: 'about_us', component: ContentManagementComponent, canActivate: [AuthGuard] },
      { path: 'user_agreement', component: ContentManagementComponent, canActivate: [AuthGuard] },
      { path: '', redirectTo: '/admin/dashboard', pathMatch: 'full' },
    ]
  },

  {
    path: '',
    redirectTo: '/admin/dashboard',
    pathMatch: 'full'
  },
  { path: '**', component: PagenotfoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false, useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
